<template>
  <div>
    <v-page id="kamers" justify="center" align="center" class="d-flex text-center mt-16" no-auto-height>
      <v-container>
        <v-row>
          <v-col class="font-weight-bold" style="font-size: 1rem;">{{ $t("vacature.header") }}</v-col>
        </v-row>
        <v-row>
          <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("vacature.subheader") }}</h1></v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" lg="8" xl="6">
            <i18n path="vacature.intro" tag="label" for="vacature.intro">
              
            </i18n>
            <p></p>
            <p></p>
          </v-col>
        </v-row>

        <v-row>
          <v-row justify="center" align="top">
          <v-col class="shrink" cols="12" md="12" lg="6">
            <v-img :src="require('@/assets/vacature130225.png')"></v-img>
          </v-col>
          <v-col>
            
            <v-row>
              <v-col class="text-left font-weight-bold">Vacature: Fulltime Allround Hotelmedewerker bij het Poorter Boutique Hotel in Brielle</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left"> Ben jij op zoek naar een dynamische en gastgerichte rol in de hotelindustrie? Het Poorter Boutique Hotel in het sfeervolle Brielle is op zoek naar een enthousiaste Allround Hotelmedewerker om ons team te versterken aankomende voorjaar en zomerperiode.

Als Allround Hotelmedewerker ben jij de sleutel tot een onvergetelijke ervaring voor onze gasten. Kom jij ons team met jouw gastvrijheid en professionaliteit verrijken? Lees dan verder!
              
               
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex text-left font-weight-bold">Over het Poorter Boutique Hotel:</v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex text-left"> Het Poorter Boutique Hotel is een luxueus boutique hotel dat gevestigd is in het historische centrum van Brielle. Met zijn unieke charme, uitstekende service en oog voor detail verwelkomt ons hotel gasten van over de hele wereld. We streven ernaar om een onvergetelijke ervaring te bieden waarbij gastvrijheid centraal staat.
              
            
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-left font-weight-bold">Functieomschrijving:</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left"> Als Allround Hotelmedewerker ben je verantwoordelijk voor het verlenen van uitstekende service aan onze gasten en draag je bij aan een soepele dagelijkse operatie van het hotel. Je speelt een belangrijke rol in het creëren van een warme en gastvrije sfeer voor onze gasten. Je werkzaamheden omvatten onder andere:
              
               <ul>
                <P></P>
                <li>Inchecken en uitchecken van gasten met vriendelijkheid en efficiëntie.</li>
                <li>Beantwoorden van vragen van gasten en het verstrekken van informatie over het hotel en de omgeving.</li>
                <li>Het verzorgen van reserveringen en het coördineren van boekingen.</li>
                <li>Assisteren bij het serveren van maaltijden en drankjes in ons restaurant en de bar.</li>
                <li>Zorg dragen voor eventuele speciale verzoeken of behoeften van gasten.</li>
                <li>Bijdragen aan een veilige en nette werkomgeving.</li>
                <li>Samenwerken met collega's om een vlekkeloze gastervaring te garanderen.</li>
              </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-left font-weight-bold">Vereisten:</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left"> 
              
               <ul>
                <P></P>
                <li>Ervaring in de horeca of hotelbranche is een pluspunt, maar niet vereist.</li>
                <li>Uitstekende communicatieve vaardigheden en een representatief voorkomen.</li>
                <li>Gastvrijheid zit in je bloed; je bent vriendelijk, attent en servicegericht.</li>
                <li>Flexibel en bereid om in verschillende diensten te werken, waaronder avonden, weekenden en feestdagen.</li>
                <li>Teamspeler met een proactieve instelling en een hands-on mentaliteit.</li>
                <li>Goede beheersing van de Nederlandse en Engelse taal, zowel mondeling als schriftelijk.</li>
                
              </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-left font-weight-bold">Wat bieden wij?</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left"> 
              
               <ul>
                <P></P>
                <li>Een uitdagende en afwisselende functie in een inspirerende werkomgeving.</li>
                <li>De functie is per direct beschikbaar en zal duren tot uiterlijk eind september 2025 in overleg.</li>
                <li>Verantwoordelijkheid in het hotel.</li>
                <li>Een competitief salaris en aantrekkelijke secundaire arbeidsvoorwaarden.</li>
                <li>Een hecht en gezellig team waarin teamwork en positieve samenwerking centraal staan.</li>
                
                
              </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-left"> Als allround hotelmedewerker bij het Poorter Boutique Hotel heb je de kans om deel uit te maken van een fantastisch team dat streeft naar uitmuntendheid in gastvrijheid. Je krijgt de mogelijkheid om je vaardigheden en kennis verder te ontwikkelen binnen een professionele en gastgerichte omgeving.
               
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-left font-weight-bold">Enthousiast?</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left"> Klinkt dit als de perfecte uitdaging voor jou om ons te komen ondersteunen tijdens het seizoen? Wacht dan niet langer en stuur ons je CV en motivatiebrief naar info@poorterhotel.nl We kijken ernaar uit om van jou te horen en wie weet verwelkomen we jou binnenkort in ons team!
              
            
              </v-col>
            </v-row>
            <v-row>
              <v-col><v-btn color="primary" x-large class="mt-6 rounded-0" style="color: black; font-weight: bold" href="https://www.poorterhotel.nl/nl/contact" target="_blank">{{ $t('Contact') }}</v-btn>
              <p></p>
              <p></p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        </v-row>
       

      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/views/Footer";
import VPage from "@/components/VPage";
import Rooms from  "@/data/rooms";
export default {
name: "Kamers",
  components: {VPage, Footer},
  data() {
  return {
    fotos: Rooms.map(x => x.fotos[0]),
    rooms: Rooms
  }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
